/* eslint-disable linebreak-style */
export const validateToken = () => {
  const token = localStorage.getItem('token');
  const expires = localStorage.getItem('expiresToken');

  return token && expires && new Date(expires) > new Date();
};

export const parseParamToObject = (search) => {
  if (search) {
    const params = search.substring(1).split('&');
    const obj = {};

    params.forEach((param) => {
      const paramObj = param.split('=');
      obj[paramObj[0]] = decodeURI(paramObj[1]);
    });

    return obj;
  }
  return {};
};
