const _loading = () => {
    return {
        top: 'calc(50% - 50px)', 
        left: 'calc(50% - 50px)', 
        position: 'absolute', 
        width: '100px', 
        height: '100px', 
        borderWidth: '10px' 
    }
}

const _divLoading = () => {
    return {
            position: "absolute", 
            zIndex: 1, 
            width: '100%', 
            height: '100%', 
            background: 'rgba(255, 255, 255, 0.7)'
    }
}

export const Loading = _loading()
export const DivLoading = _divLoading()