/* eslint-disable */
import * as Excel from 'exceljs/dist/es5/exceljs.browser';
import FileSaver from 'file-saver';

const gerarExcel = (data, desconto) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Planilha Geradora');
  worksheet.columns = [
    {
      header: 'FOTO', key: 'Foto', width: '22', height: '32',
    },
    { header: 'REF', key: 'ItemCode', width: '12' },
    { header: 'DESCRIÇÃO', key: 'FrgnName', width: '46' },
    { header: 'COD EAN', key: 'CodeBars', width: '48' },
    { header: 'COD DUN', key: 'CodDun', width: '26' },
    { header: 'MARCA', key: 'Marca', width: '18' },
    { header: 'CATEGORIA', key: 'Categoria', width: '18' },
    { header: 'SUBCATEGORIA', key: 'Subcategoria', width: '20' },
    { header: 'LINHA', key: 'Linha', width: '18' },
    { header: 'FONTE', key: 'FonteProduto', width: '16' },
    { header: 'ORIGEM', key: 'PaisOrigem', width: '14' },
    { header: 'ONHOLD', key: 'onHold', width: '24' },
    { header: 'PREÇO SEM DESCONTO', key: 'PrecoSemDesc', width: '14' },
    { header: '% DE DESCONTO', key: 'PercentDesc', width: '14' },
    { header: 'PROMOÇÃO DENTRO SP', key: 'PromoSP', width: '16' },
    { header: 'PREÇO COM DESCONTO SP', key: 'PrecoComDescSP', width: '20' },
    { header: 'PROMOÇÃO FORA SP', key: 'PromoForaSP', width: '14' },
    { header: 'PREÇO COM DESCONTO FORA SP', key: 'PrecoComDescForaSP', width: '26' },
    { header: 'ESTOQUE FÍSICO', key: 'EstoqueCajamar', width: '16' },
    { header: 'PEDIDOS EM CARTEIRA', key: 'ReservadoCajamar', width: '16' },
    { header: 'RESERVAS PARA PEDIDO DE VENDA', key: 'BloqueadoPedido', width: '16' },
    { header: 'PRÉ-FATURAS EM CARGA', key: 'Picking', width: '16' },
    { header: 'EM TRÂNSITO', key: 'TransitoCajamar', width: '16' },
    { header: 'PREVISTO EMBARCADO', key: 'Prev1', width: '16' },
    { header: 'PREV CHEGADA', key: 'DataPrevisao', width: '16' },
    { header: 'PRODUZINDO', key: 'Produzindo', width: '16' },
    { header: 'DATA PREV EMB', key: 'DataPrevEmb', width: '16' },
    { header: 'Disp. Fat. Imediato (Fisico-Pedidos em Carteira+Em Trânsito)', key: 'EstoqueDisponivel', width: '16' },
    { header: 'ENTRADA DE CONTAINER', key: 'EntradaContainer', width: '16' },
    { header: 'QTD MASTER', key: 'QtdCaixaMaster', width: '14' },
    { header: 'QTD INNER', key: 'QtdCaixaInner', width: '14' },
    { header: 'TIPO EMBALAGEM', key: 'TipoEmbalagem', width: '16' },
    { header: 'NCM', key: 'NcmCode', width: '14' },
    { header: 'IPI %', key: 'IPI', width: '14' },
    { header: '% MVA - AC', key: 'MVA.AC', width: '12' },
    { header: '% ST - AC', key: 'ST.AC', width: '12' },
    { header: '% MVA - AL', key: 'MVA.AL', width: '12' },
    { header: '% ST - AL', key: 'ST.AL', width: '12' },
    { header: '% MVA - AM', key: 'MVA.AM', width: '12' },
    { header: '% ST - AM', key: 'ST.AM', width: '12' },
    { header: '% MVA - AP', key: 'MVA.AP', width: '12' },
    { header: '% ST - AP', key: 'ST.AP', width: '12' },
    { header: '% MVA - BA', key: 'MVA.BA', width: '12' },
    { header: '% ST - BA', key: 'ST.BA', width: '12' },
    { header: '% MVA - CE', key: 'MVA.CE', width: '12' },
    { header: '% ST - CE', key: 'ST.CE', width: '12' },
    { header: '% MVA - DF', key: 'MVA.DF', width: '12' },
    { header: '% ST - DF', key: 'ST.DF', width: '12' },
    { header: '% MVA - ES', key: 'MVA.ES', width: '12' },
    { header: '% ST - ES', key: 'ST.ES', width: '12' },
    { header: '% MVA - GO', key: 'MVA.GO', width: '12' },
    { header: '% ST - GO', key: 'ST.GO', width: '12' },
    { header: '% MVA - MA', key: 'MVA.MA', width: '12' },
    { header: '% ST - MA', key: 'ST.MA', width: '12' },
    { header: '% MVA - MT', key: 'MVA.MT', width: '12' },
    { header: '% ST - MT', key: 'ST.MT', width: '20' },
    { header: '% MVA - MS', key: 'MVA.MS', width: '12' },
    { header: '% ST - MS', key: 'ST.MS', width: '12' },
    { header: '% MVA - MG', key: 'MVA.MG', width: '12' },
    { header: '% ST - MG', key: 'ST.MG', width: '12' },
    { header: '% MVA - PA', key: 'MVA.PA', width: '12' },
    { header: '% ST - PA', key: 'ST.PA', width: '12' },
    { header: '% MVA - PB', key: 'MVA.PB', width: '12' },
    { header: '% ST - PB', key: 'ST.PB', width: '12' },
    { header: '% MVA - PR', key: 'MVA.PR', width: '12' },
    { header: '% ST - PR', key: 'ST.PR', width: '12' },
    { header: '% MVA - PE', key: 'MVA.PE', width: '12' },
    { header: '% ST - PE', key: 'ST.PE', width: '12' },
    { header: '% MVA - PI', key: 'MVA.PI', width: '12' },
    { header: '% ST - PI', key: 'ST.PI', width: '12' },
    { header: '% MVA - RN', key: 'MVA.RN', width: '12' },
    { header: '% ST - RN', key: 'ST.RN', width: '12' },
    { header: '% MVA - RS', key: 'MVA.RS', width: '12' },
    { header: '% ST - RS', key: 'ST.RS', width: '12' },
    { header: '% MVA - RJ', key: 'MVA.RJ', width: '12' },
    { header: '% ST - RJ', key: 'ST.RJ', width: '12' },
    { header: '% MVA - RO', key: 'MVA.RO', width: '12' },
    { header: '% ST - RO', key: 'ST.RO', width: '12' },
    { header: '% MVA - RR', key: 'MVA.RR', width: '12' },
    { header: '% ST - RR', key: 'ST.RR', width: '12' },
    { header: '% MVA - SC', key: 'MVA.SC', width: '12' },
    { header: '% ST - SC', key: 'ST.SC', width: '12' },
    { header: '% MVA - SP', key: 'MVA.SP', width: '12' },
    { header: '% ST - SP', key: 'ST.SP', width: '12' },
    { header: '% MVA - SE', key: 'MVA.SE', width: '12' },
    { header: '% ST - SE', key: 'ST.SE', width: '12' },
    { header: '% MVA - TO', key: 'MVA.TO', width: '12' },
    { header: '% ST - TO', key: 'ST.TO', width: '12' },
    { header: 'CEST', key: 'CEST', width: '12' },
    { header: 'Comprimento', key: 'Comprimento', width: '14' },
    { header: 'Largura', key: 'Largura', width: '12' },
    { header: 'Altura', key: 'Altura', width: '12' },
    { header: 'Peso Bruto', key: 'PesoBruto', width: '12' },
    { header: 'Cor', key: 'Cor', width: '12' },
    { header: 'Material', key: 'Material', width: '18' },
    { header: 'Outlet', key: 'Outlet', width: '18' },
    { header: 'Promoção', key: 'Promocao', width: '18' },
  ];
  data.map((item, index) => {
    const desc = Number(desconto) > Number(item.promoSP) ? Number(desconto) : Number(item.promoSP);
    const descForaSP = Number(desconto) > Number(item.promoForaSP) ? Number(desconto) : Number(item.promoForaSP);
    const row = {
      Foto: item.imageBase64 ? 'S' : 'N',
      ItemCode: isNaN(item.itemCode) ? item.itemCode : Number(item.itemCode),
      FrgnName: item.frgnName,
      CodeBars: Number(item.codeBars),
      CodDun: Number(item.codDun),
      Marca: item.marca,
      Categoria: item.categoria,
      Subcategoria: item.subcategoria,
      Linha: item.linha,
      FonteProduto: item.fonteProduto,
      PaisOrigem: item.paisOrigem,
      NaoComprar: item.naoComprar === 'N' || item.naoComprar === null ? 'Não' : 'Sim',
      PrecoSemDesc: Number(item.preco),
      PercentDesc: Number(desconto.toFixed(4)),
      PromoSP: Number((Number(item.promoSP) / 100).toFixed(4)),
      PrecoComDescSP: Number(item.preco) * (1 - desc / 100),
      PromoForaSP: Number((Number(item.promoForaSP) / 100).toFixed(4)),
      PrecoComDescForaSP: Number(item.preco) * (1 - descForaSP / 100),
      EstoqueTotal: Number(item.estoqueTotal),
      EstoqueCajamar: Number(item.estoqueCajamar),
      ReservadoCajamar: Number(item.reservadoCajamar),
      BloqueadoPedido: Number(item.bloqueadoPedido),
      Picking: item.picking,
      EstoqueDisponivel: Number(item.disp1),
      TransitoCajamar: Number(item.transitoCajamar),
      Produzindo: Number(item.produzindo),
      DataPrevEmb: (item.dataPrevEmb && item.dataPrevEmb.trim() === '01/01/1970' ? '-' : item.dataPrevEmb),
      Embarcado: Number(item.embarcado),
      DataPrevisao: (item.dataPrevisao && item.dataPrevisao.trim() === '01/01/1970' ? '-' : item.dataPrevisao),
      Prev1: Number(item.embarcado1),
      EntradaContainer: Number(item.entradaContainer),
      QtdCaixaMaster: Number(item.qtdCaixaMaster),
      QtdCaixaInner: Number(item.qtdCaixaInner),
      TipoEmbalagem: item.tipoEmbalagem,
      NcmCode: item.ncmCode,
      IPI: Number(item.IPI),
      'MVA.AC': Number(item.MVA.AC),
      'ST.AC': Number(item.ST.AC),
      'MVA.AL': Number(item.MVA.AL),
      'ST.AL': Number(item.ST.AL),
      'MVA.AM': Number(item.MVA.AM),
      'ST.AM': Number(item.ST.AM),
      'MVA.AP': Number(item.MVA.AP),
      'ST.AP': Number(item.ST.AP),
      'MVA.BA': Number(item.MVA.BA),
      'ST.BA': Number(item.ST.BA),
      'MVA.CE': Number(item.MVA.CE),
      'ST.CE': Number(item.ST.CE),
      'MVA.DF': Number(item.MVA.DF),
      'ST.DF': Number(item.ST.DF),
      'MVA.ES': Number(item.MVA.ES),
      'ST.ES': Number(item.ST.ES),
      'MVA.GO': Number(item.MVA.GO),
      'ST.GO': Number(item.ST.GO),
      'MVA.MA': Number(item.MVA.MA),
      'ST.MA': Number(item.ST.MA),
      'MVA.MT': Number(item.MVA.MT),
      'ST.MT': isNaN(item.ST.MT) ? item.ST.MT : Number(item.ST.MT),
      'MVA.MS': Number(item.MVA.MS),
      'ST.MS': Number(item.ST.MS),
      'MVA.MG': Number(item.MVA.MG),
      'ST.MG': Number(item.ST.MG),
      'MVA.PA': Number(item.MVA.PA),
      'ST.PA': Number(item.ST.PA),
      'MVA.PB': Number(item.MVA.PB),
      'ST.PB': Number(item.ST.PB),
      'MVA.PR': Number(item.MVA.PR),
      'ST.PR': Number(item.ST.PR),
      'MVA.PE': Number(item.MVA.PE),
      'ST.PE': Number(item.ST.PE),
      'MVA.PI': Number(item.MVA.PI),
      'ST.PI': Number(item.ST.PI),
      'MVA.RN': Number(item.MVA.RN),
      'ST.RN': Number(item.ST.RN),
      'MVA.RS': Number(item.MVA.RS),
      'ST.RS': Number(item.ST.RS),
      'MVA.RJ': Number(item.MVA.RJ),
      'ST.RJ': Number(item.ST.RJ),
      'MVA.RO': Number(item.MVA.RO),
      'ST.RO': Number(item.ST.RO),
      'MVA.RR': Number(item.MVA.RR),
      'ST.RR': Number(item.ST.RR),
      'MVA.SC': Number(item.MVA.SC),
      'ST.SC': Number(item.ST.SC),
      'MVA.SP': Number(item.MVA.SP),
      'ST.SP': Number(item.ST.SP),
      'MVA.SE': Number(item.MVA.SE),
      'ST.SE': Number(item.ST.SE),
      'MVA.TO': Number(item.MVA.TO),
      'ST.TO': Number(item.ST.TO),
      CEST: isNaN(item.CEST) && item.CEST.trim() === '' ? item.CEST : Number(item.CEST),
      Comprimento: item.comprimento,
      Largura: item.largura,
      Altura: item.altura,
      PesoBruto: item.pesoBruto,
      Cor: item.cor,
      Material: item.material,
      Outlet: item.outlet,
      Promocao: item.promocao,
      onHold: item.onHold === 'N' ? 'Não' : 'Sim'
    }

    worksheet.addRow(row);
    if (item.imageBase64) {
      const imgID = workbook.addImage({
        base64: `${item.imageBase64}`,
        extension: 'jpeg',
      });
      item.imageBase64 = null;
      worksheet.addImage(imgID, `A${index + 2}:A${index + 2}`);
    }

    return null;
  });
  data.map((item, index) => {
    const DataCellStyle = {
      font: {
        name: 'Calibri',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };

    // Define estilos de células individuais
    const currentRow = index + 2;
    const dataRow = worksheet.getRow(currentRow);

    dataRow.font = DataCellStyle.font;
    dataRow.alignment = DataCellStyle.alignment;
    dataRow.height = 110;

    dataRow.getCell('ItemCode').style.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    };

    dataRow.getCell('FrgnName').style.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dataRow.getCell('FrgnName').style.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    dataRow.getCell('PrecoSemDesc')
      .style.fill = {
      fgColor: { argb: 'FDE9D9' },
      type: 'pattern',
      pattern: 'solid',
    };

    dataRow.getCell('PrecoComDescSP').style.fill = {
      fgColor: { argb: 'EBF1DE' },
      type: 'pattern',
      pattern: 'solid',
    };

    dataRow.getCell('PrecoComDescForaSP').style.fill = {
      fgColor: { argb: 'EBF1DE' },
      type: 'pattern',
      pattern: 'solid',
    };

    dataRow.getCell('ST.MT').style.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    dataRow.getCell('Subcategoria').style.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    dataRow.getCell('Categoria').style.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    dataRow.getCell('Material').style.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    return null;
  });

  // Define formato das colunas de dinheiro
  worksheet.getColumn('M').numFmt = 'R$ 0.00';
  worksheet.getColumn('P').numFmt = 'R$ 0.00';
  worksheet.getColumn('R').numFmt = 'R$ 0.00';

  // Define formato das colunas de número
  worksheet.getColumn('D').numFmt = '0';
  worksheet.getColumn('E').numFmt = '0';

  // Define formato das colunas de percentual
  worksheet.getColumn('N').numFmt = '0.00%'
  worksheet.getColumn('O').numFmt = '0.00%'
  worksheet.getColumn('Q').numFmt = '0.00%'

  worksheet.views = [
    {
      state: 'frozen', xSplit: 0, ySplit: 1, zoomScale: 80, zoomScaleNormal: 80,
    },
  ];
  estilizarCabecalhos(worksheet);
  return workbook;
};

export const excelItensGeradoraAntigo = (data, desconto = 0) => {
  const workbook = gerarExcel(data, desconto);
  return imprimirExcel(workbook, 'Planilha Geradora');
};

const imprimirExcel = (workbook, fileName = 'temp') => workbook.xlsx.writeBuffer()
  .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`))
  .catch(err => console.log('Error writing excel export', err));

const estilizarCabecalhos = (worksheet) => {
  const HeaderStyle = {
    font: {
      name: 'Verdana',
      size: 10,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    },
    fill: {
      fgColor: { argb: 'DCE6F1' },
      type: 'pattern',
      pattern: 'solid',
    },
  };
  // Define estilização para o cabeçalho
  const headerRow = worksheet.getRow(1);
  headerRow.font = HeaderStyle.font;
  headerRow.alignment = HeaderStyle.alignment;
  headerRow.border = HeaderStyle.border;
  headerRow.fill = HeaderStyle.fill;

  const fillPrecoSemDesconto = {
    fgColor: { argb: 'FCD5B4' },
    type: 'pattern',
    pattern: 'solid',
  };
  worksheet.getCell('M1').fill = fillPrecoSemDesconto;

  const fillPrecoComDesconto = {
    fgColor: { argb: 'D8E4BC' },
    type: 'pattern',
    pattern: 'solid',
  };
  worksheet.getCell('P1').fill = fillPrecoComDesconto;
  worksheet.getCell('R1').fill = fillPrecoComDesconto;

  const fontPromocaoSP = Object.create(HeaderStyle.font);
  fontPromocaoSP.color = {
    argb: 'FF0000',
  };
  worksheet.getCell('O1').font = fontPromocaoSP;
  worksheet.getCell('Q1').font = fontPromocaoSP;
  headerRow.height = 30;
};
