import React from 'react';
import { Snackbar } from '@material-ui/core';
import SnackbarContent from './CustomSnackbarContent';

function CustomSnackBar(props) {
  const {
    message, variant, open, onClose,
  } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={10000}

    >
      <SnackbarContent message={message} variant={variant} open={open} onClose={onClose} />
    </Snackbar>
  );
}

export default CustomSnackBar;
