import React from 'react';
import { Spinner } from 'react-bootstrap';
import * as Styles from '../CSS';

export default function Loading() {
  return (
    <div style={Styles.DivLoading}>
      <Spinner animation="border" variant="dark" style={Styles.Loading} />
    </div>
  );
}
