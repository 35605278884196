/* eslint-disable */
import React from 'react';
import './App.css';
import dotenv from 'dotenv';
import AppGeradora from './components/AppGeradora';

function App() {
  dotenv.config({
    path: process.env.NODE_ENV === 'production' ? '.env' : '.env.development',
  });
  return (
    <div className="App">
      <AppGeradora />
    </div>
  );
}

export default App;
