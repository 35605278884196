/* eslint-disable */
import React from "react";
import { FormControl, Select, MenuItem, Tooltip } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@mui/material/Button';
import "bootstrap/dist/css/bootstrap.css";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import ItensGeradora from "./ItensGeradora";
import Loading from "./Loading";
import { excelItensGeradora } from "../Utils/Excel";
import { excelItensGeradoraAntigo } from '../Utils/ExcelOldVersion'
import { maskDesconto } from "../Masks/Desconto";
import { validateToken } from "../Utils/Cookie";
import axios from "axios";
const isDesktop = window.screen.width > 940;

export default class Geradora extends React.Component {
  state = {
    codEmpresa: 1,
    txtItens: "",
    itensGeradora: [],
    isLoading: false,
    desconto: 0,
    txtDesc: "",
    clickNewVersion: false,
  };

  componentDidMount() {
    const { token, history } = this.props;
    if (!validateToken()) {
      history.push("/login?logout=true");
    }
    const codEmp = localStorage.getItem("empresa");
    this.setState({ codEmpresa: Number(codEmp) });
  }

  txtItens_OnChange = (e) => {
    const txt = e.target.value;
    this.setState({
      txtItens: txt,
    });
  };

  txtDesc_OnChange = (e) => {
    const value = maskDesconto(e.target.value);
    this.setState({ txtDesc: value });
  };

  getItensGeradora = async (versao) => {
    const { txtItens } = this.state;
    const arrayOrdem = txtItens.split(" ");
    this.setState((prevState) => ({
      isLoading: true,
      desconto: prevState.txtDesc
        ? parseFloat(prevState.txtDesc.replace(",", "."))
        : 0,
    }));

    const arrayGroupItens = [];
    const arrayItens = [...arrayOrdem].filter((item) => item !== "");
    while (arrayItens.length > 50) {
      arrayGroupItens.push(arrayItens.splice(0, 50));
    }
    arrayGroupItens.push(arrayItens);

    const retornoWS = [];
    try {
      for (let i = 0; i < arrayGroupItens.length; i++) {
        const group = arrayGroupItens[i];
        const route = `${process.env.REACT_APP_MIDDLEWARE_API}/produto-geradora`;
        const { data } = await axios.post(
          route,
          {
            codEmp: localStorage.getItem("empresa"),
            codPro: group,
            desconto_aplicado: this.state.txtDesc || 0,
            versao: versao,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        retornoWS.push(data.produtos);
      }

      this.setState({
        itensGeradora: retornoWS
          .flat()
          .sort(
            (a, b) =>
              arrayOrdem.indexOf(a.itemCode) - arrayOrdem.indexOf(b.itemCode)
          ),
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  btnExcel_OnClick = (e) => {
    const { itensGeradora, desconto } = this.state;
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.clickNewVersion) {
      excelItensGeradora(itensGeradora, desconto)
    } else {
      excelItensGeradoraAntigo(itensGeradora, desconto)
    }
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 500);
  };

  desconectar = () => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const url = `${loginApi}/signout?redirectTo=${frontUrl}/login?logout=true`;
    const codigodaempresa = localStorage.getItem("codigodaempresa");

    localStorage.clear();
    localStorage.setItem("codigodaempresa", codigodaempresa);
    window.location.href = url;
  };

  handleChangeEnterprise = (codEmp) => {
    localStorage.setItem("empresa", String(codEmp));
    this.setState({
      codEmpresa: Number(codEmp),
      txtItens: "",
      itensGeradora: [],
      isLoading: false,
      desconto: 0,
      txtDesc: "",
    });
  };

  render() {
    const nomeEmpresa = { nome: "" };
    switch (localStorage.empresa) {
      case "1":
        nomeEmpresa.nome = "Wolff";
        break;
      case "2":
        nomeEmpresa.nome = "Lyor";
        break;
      case "3":
        nomeEmpresa.nome = "Urban";
        break;
    }
    const { isLoading, itensGeradora, desconto, txtItens, txtDesc } =
      this.state;
    return (
      <div>
        {isLoading && <Loading />}
        <div className="row" style={{ alignItems: 'center' }}>
          <h1>Planilha Geradora - </h1>
          <FormControl
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              minWidth: "100px",
            }}
          >
            <Select
              value={this.state.codEmpresa}
              onChange={(e) => this.handleChangeEnterprise(Number(e.target.value))}
            >
              <MenuItem value={1}>
                <span style={{ fontWeight: "bold", fontSize: "30px" }}>
                  WOLFF
                </span>
              </MenuItem>
              <MenuItem value={2}>
                <span style={{ fontWeight: "bold", fontSize: "30px" }}>
                  LYOR
                </span>
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              position: "absolute",
              right: 20,
              top: 10,
            }}
          >
            <Button style={{ backgroundColor: '#1A3661', textTransform: 'none' }} variant="contained" size="md" id="btnDesconectar" onClick={this.desconectar}>
              Desconectar
            </Button>
          </div>
        </div>
        <div className="row">
          <Form.Label htmlFor="txtItens">Itens: </Form.Label>
          <Form.Control
            type="text"
            id="txtItens"
            style={{ width: "600px", marginLeft: "20px" }}
            value={txtItens}
            onChange={this.txtItens_OnChange}
          />
          <Form.Label htmlFor="txtDesconto" style={{ marginLeft: "40px" }}>
            Desconto:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            id="txtDesconto"
            maxLength="5"
            style={{ marginLeft: "20px", width: "100px" }}
            value={txtDesc}
            onChange={this.txtDesc_OnChange}
          />
        </div>
        <div className="row">
          <Button
            style={{ textTransform: 'none', backgroundColor: '#1A3661' }}
            size="md"
            variant="contained"
            id="btnGerarAntiga"
            onClick={(e) => {
              e.preventDefault();
              this.getItensGeradora(e.target.id);
              this.setState({ clickNewVersion: false })
            }}
            endIcon={
              <Tooltip title="Versão mais lenta e pesada, exibindo a imagem direto no arquivo. Não depende de plataformas online, sendo acessível instantaneamente.">
                <ErrorOutlineIcon fontSize="small" />
              </Tooltip>
            }
          >
            Gerar Versão com Fotos Locais
          </Button>
          <Button
            size="md"
            variant="contained"
            id="btnGerarNova"
            onClick={(e) => {
              e.preventDefault();
              this.getItensGeradora(e.target.id);
              this.setState({ clickNewVersion: true })
            }}
            style={{ textTransform: 'none', marginLeft: "20px", backgroundColor: '#1A3661' }}
            endIcon={
              <Tooltip title="Rápido e leve, este botão oferece respostas ágeis, mas requer o Excel online para ativar suas imagens ou funcionalidades visuais.">
                <ErrorOutlineIcon fontSize="small" />
              </Tooltip>
            }
          >
            Gerar Versão com Fotos Online
          </Button>
          <Button
            size="md"
            variant="contained"
            id="btnExcel"
            hidden={!(itensGeradora.length > 0)}
            onClick={this.btnExcel_OnClick}
            style={{ textTransform: 'none', marginLeft: "20px", backgroundColor: '#1A3661' }}
          >
            Gerar Excel
          </Button>
        </div>
        {itensGeradora.length > 0 ? (
          <ItensGeradora itens={itensGeradora} desconto={desconto} />
        ) : (
          ""
        )}
      </div>
    );
  }
}

Geradora.propTypes = {
  token: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  clearSession: PropTypes.func.isRequired,
};
