/* eslint-disable */
export const numberToMoney = (currency, value) => {
  return value ? `${currency} ${Number(value).toFixed(2).toString().replace('.', ',')}` : ''
};

export const formatReais = value => numberToMoney('R$', value);

export const formatPercent = (value) => {
  try {
    const parsed = parseFloat(value);
    return `${parsed.toFixed(0).toString().replace('.', ',')}%`;
  } catch (err) {
    return '0,00%';
  }
};

export const formatPercentTwoHouseDecimal = (value) => {
  try {
    const parsed = parseFloat(value);
    return `${parsed.toFixed(2).toString().replace('.', ',')}%`;
  } catch (err) {
    return '0,00%';
  }
};

export const dateToString = (dateString) => {
  if (dateString) {
    try {
      const date = new Date(dateString);
      const dia = date.getDate() > 9 ? date.getDate().toString() : `0${date.getDate().toString()}`;
      const mes = date.getMonth() > 8 ? (date.getMonth() + 1).toString() : `0${(date.getMonth() + 1).toString()}`;
      const ano = date.getFullYear();
      return `${dia}/${mes}/${ano}`;
    } catch (err) {
      return '';
    }
  }
  return '';
};
