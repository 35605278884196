/* eslint-disable */
import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import {
  formatReais,
  formatPercentTwoHouseDecimal,
} from '../Utils/Format';
import { foto, getUrlImagem } from '../Utils/Url';

export default class ItensGeradora extends React.PureComponent {
  // teste deploy
  render() {
    const { itens, desconto } = this.props;
    {/* <a href="#" onClick={e => foto(e, item.urlFoto)}>
                  <img id={`img${item.itemCode}`} width="64" height="64" src={item.urlFoto} alt="" />
                </a> */}
    return (
      <div className="row" style={{ width: '100%', margin: '0 auto' }}>
        <ReactTable
          data={itens}
          style={{
            position: 'absolute', top: '200px', bottom: '20px', width: '95%',
          }}
          className="-striped"
          filterable
          columns={[
            {
              Header: 'FOTO',
              id: 'FOTO',
              accessor: item => (
                <a href="#" onClick={e => foto(e, item.itemCode)}>
                  <img id={`img${item.itemCode}`} width="64" height="64" src={getUrlImagem(item.itemCode)} alt="" />
                </a>
              ),
            },
            {
              Header: 'REF',
              accessor: 'itemCode',
            },
            {
              Header: 'DESCRIÇÃO',
              accessor: 'frgnName',
              width: 400,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: 'COD EAN',
              accessor: 'codeBars',
              width: 150,
            },
            {
              Header: 'COD DUN',
              accessor: 'codDun',
              width: 150,
            },
            {
              Header: 'MARCA',
              accessor: 'marca',
              width: 150,
            },
            {
              Header: 'CATEGORIA',
              accessor: 'categoria',
              style: { whiteSpace: 'unset' },
              width: 150,
            },
            {
              Header: 'SUBCATEGORIA',
              accessor: 'subcategoria',
              style: { whiteSpace: 'unset' },
              width: 150,
            },
            {
              Header: 'LINHA',
              accessor: 'linha',
              width: 150,
            },
            {
              Header: 'FONTE',
              accessor: 'fonteProduto',
              width: 150,
            },
            {
              Header: 'ORIGEM',
              accessor: 'paisOrigem',
              width: 150,
            },
            {
              Header: 'ONHOLD',
              id: 'onHold',
              accessor: item => (item.onHold === 'N' ? 'Não' : 'Sim'),
              width: 200,
            },
            {
              Header: 'PREÇO SEM DESCONTO',
              id: 'PrecoSemDesc',
              accessor: item => formatReais(item.preco),
              width: 200,
            },
            {
              Header: '% DE DESCONTO',
              id: 'PercentDesc',
              accessor: () => formatPercentTwoHouseDecimal(desconto),
              width: 150,
            },
            {
              Header: 'PROMOÇÂO DENTRO SP',
              id: 'PromoSP',
              accessor: item => formatPercentTwoHouseDecimal(item.promoSP),
              width: 200,
            },
            {
              Header: 'PREÇO COM DESCONTO SP',
              id: 'PrecoComDescontoSP',
              accessor: item => formatReais(item.preco * (1 - (desconto > item.promoSP ? desconto : item.promoSP) / 100)),
              width: 250,
            },
            {
              Header: 'PROMOÇÂO FORA SP',
              id: 'PromoForaSP',
              accessor: item => formatPercentTwoHouseDecimal(item.promoForaSP),
              width: 200,
            },
            {
              Header: 'PREÇO COM DESCONTO FORA SP',
              id: 'PrecoComDescontoForaSP',
              accessor: item => formatReais(item.preco * (1 - (desconto > item.promoForaSP ? desconto : item.promoForaSP) / 100)),
              width: 300,
            },
            {
              Header: 'ESTOQUE FÍSICO',
              accessor: 'estoqueCajamar',
              width: 100,
            },
            {
              Header: 'PEDIDOS EM CARTEIRA',
              accessor: 'reservadoCajamar',
              width: 150,
            },
            {
              Header: 'RESERVAS PARA PEDIDO DE VENDA',
              accessor: 'bloqueadoPedido',
              width: 200,
            },
            {
              Header: 'PRÉ-FATURAS EM CARGA',
              accessor: 'picking',
              width: 150,
            },
            {
              Header: 'EM TRÂNSITO',
              accessor: 'transitoCajamar',
              width: 150,
            },
            {
              Header: 'PREVISTO EMBARCADO',
              id: 'Prev1',
              accessor: 'embarcado1',
              width: 150,
            },
            {
              Header: 'PREV CHEGADA',
              id: 'DataPrevisao',
              accessor: item => (item.dataPrevisao && item.dataPrevisao.trim() === '01/01/1970' ? '-' : item.dataPrevisao),
              width: 150,
            },
            {
              Header: 'PRODUZINDO',
              accessor: 'produzindo',
              width: 150,
            },
            {
              Header: 'DATA PREV EMB',
              id: 'DataPrevEmb',
              accessor: item => (item.dataPrevEmb && item.dataPrevEmb.trim() === '01/01/1970' ? '-' : item.dataPrevEmb),
              width: 150,
            },
            {
              Header: 'Disp. Fat. Imediato (Fisico-Pedidos em Carteira+Em Trânsito)',
              id: 'Disponivel',
              accessor: 'disp1',
              width: 250,
            },
            {
              Header: 'ENTRADA DE CONTAINER',
              accessor: 'entradaContainer',
              width: 200,
            },
            {
              Header: 'QTD MASTER',
              accessor: 'qtdCaixaMaster',
              width: 150,
            },
            {
              Header: 'QTD INNER',
              accessor: 'qtdCaixaInner',
              width: 150,
            },
            {
              Header: 'TIPO EMBALAGEM',
              accessor: 'tipoEmbalagem',
              width: 200,
            },
            {
              Header: 'NCM',
              accessor: 'ncmCode',
              width: 150,
            },
            {
              Header: '% IPI',
              id: 'IPI',
              accessor: item => Number(item.IPI).toLocaleString(),
              width: 100,
            },
            {
              Header: '% MVA - AC',
              id: 'MVA.AC',
              accessor: item => (isNaN(item.MVA.AC) ? item.MVA.AC : Number(item.MVA.AC).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - AC',
              id: 'ST.AC',
              accessor: item => (isNaN(item.ST.AC) ? item.ST.AC : Number(item.ST.AC).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - AL',
              id: 'MVA.AL',
              accessor: item => (isNaN(item.MVA.AL) ? item.MVA.AL : Number(item.MVA.AL).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - AL',
              id: 'ST.AL',
              accessor: item => (isNaN(item.ST.AL) ? item.ST.AL : Number(item.ST.AL).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - AM',
              id: 'MVA.AM',
              accessor: item => (isNaN(item.MVA.AM) ? item.MVA.AM : Number(item.MVA.AM).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - AM',
              id: 'ST.AM',
              accessor: item => (isNaN(item.ST.AM) ? item.ST.AM : Number(item.ST.AM).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - AP',
              id: 'MVA.AP',
              accessor: item => (isNaN(item.MVA.AP) ? item.MVA.AP : Number(item.MVA.AP).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - AP',
              id: 'ST.AP',
              accessor: item => (isNaN(item.ST.AP) ? item.ST.AP : Number(item.ST.AP).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - BA',
              id: 'MVA.BA',
              accessor: item => (isNaN(item.MVA.BA) ? item.MVA.BA : Number(item.MVA.BA).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - BA',
              id: 'ST.BA',
              accessor: item => (isNaN(item.ST.BA) ? item.ST.BA : Number(item.ST.BA).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - CE',
              id: 'MVA.CE',
              accessor: item => (isNaN(item.MVA.CE) ? item.MVA.CE : Number(item.MVA.CE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - CE',
              id: 'ST.CE',
              accessor: item => (isNaN(item.ST.CE) ? item.ST.CE : Number(item.ST.CE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - DF',
              id: 'MVA.DF',
              accessor: item => (isNaN(item.MVA.DF) ? item.MVA.DF : Number(item.MVA.DF).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - DF',
              id: 'ST.DF',
              accessor: item => (isNaN(item.ST.DF) ? item.ST.DF : Number(item.ST.DF).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - ES',
              id: 'MVA.ES',
              accessor: item => (isNaN(item.MVA.ES) ? item.MVA.ES : Number(item.MVA.ES).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - ES',
              id: 'ST.ES',
              accessor: item => (isNaN(item.ST.ES) ? item.ST.ES : Number(item.ST.ES).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - GO',
              id: 'MVA.GO',
              accessor: item => (isNaN(item.MVA.GO) ? item.MVA.GO : Number(item.MVA.GO).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - GO',
              id: 'ST.GO',
              accessor: item => (isNaN(item.ST.GO) ? item.ST.GO : Number(item.ST.GO).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - MA',
              id: 'MVA.MA',
              accessor: item => (isNaN(item.MVA.MA) ? item.MVA.MA : Number(item.MVA.MA).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - MA',
              id: 'ST.MA',
              accessor: item => (isNaN(item.ST.MA) ? item.ST.MA : Number(item.ST.MA).toLocaleString()),
              width: 200,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: '% MVA - MT',
              id: 'MVA.MT',
              accessor: item => (isNaN(item.MVA.MT) ? item.MVA.MT : Number(item.MVA.MT).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - MT',
              id: 'ST.MT',
              accessor: item => (isNaN(item.ST.MT) ? item.ST.MT : Number(item.ST.MT).toLocaleString()),
              width: 200,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: '% MVA - MS',
              id: 'MVA.MS',
              accessor: item => (isNaN(item.MVA.MS) ? item.MVA.MS : Number(item.MVA.MS).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - MS',
              id: 'ST.MS',
              accessor: item => (isNaN(item.ST.MS) ? item.ST.MS : Number(item.ST.MS).toLocaleString()),
              width: 200,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: '% MVA - MG',
              id: 'MVA.MG',
              accessor: item => (isNaN(item.MVA.MG) ? item.MVA.MG : Number(item.MVA.MG).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - MG',
              id: 'ST.MG',
              accessor: item => (isNaN(item.ST.MG) ? item.ST.MG : Number(item.ST.MG).toLocaleString()),
              width: 150,
            },
            {
              Header: '% MVA - PA',
              id: 'MVA.PA',
              accessor: item => (isNaN(item.MVA.PA) ? item.MVA.PA : Number(item.MVA.PA).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - PA',
              id: 'ST.PA',
              accessor: item => (isNaN(item.ST.PA) ? item.ST.PA : Number(item.ST.PA).toLocaleString()),
              width: 200,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: '% MVA - PB',
              id: 'MVA.PB',
              accessor: item => (isNaN(item.MVA.PB) ? item.MVA.PB : Number(item.MVA.PB).toLocaleString()),
              width: 150,
            },
            {
              Header: '% ST - PB',
              id: 'ST.PB',
              accessor: item => (isNaN(item.ST.PB) ? item.ST.PB : Number(item.ST.PB).toLocaleString()),
              width: 200,
              style: { whiteSpace: 'unset' },
            },
            {
              Header: '% MVA - PR',
              id: 'MVA.PR',
              accessor: item => (isNaN(item.MVA.PR) ? item.MVA.PR : Number(item.MVA.PR).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - PR',
              id: 'ST.PR',
              accessor: item => (isNaN(item.ST.PR) ? item.ST.PR : Number(item.ST.PR).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - PE',
              id: 'MVA.PE',
              accessor: item => (isNaN(item.MVA.PE) ? item.MVA.PE : Number(item.MVA.PE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - PE',
              id: 'ST.PE',
              accessor: item => (isNaN(item.ST.PE) ? item.ST.PE : Number(item.ST.PE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - PI',
              id: 'MVA.PI',
              accessor: item => (isNaN(item.MVA.PI) ? item.MVA.PI : Number(item.MVA.PI).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - PI',
              id: 'ST.PI',
              accessor: item => (isNaN(item.ST.PI) ? item.ST.PI : Number(item.ST.PI).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - RS',
              id: 'MVA.RS',
              accessor: item => (isNaN(item.MVA.RS) ? item.MVA.RS : Number(item.MVA.RS).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - RS',
              id: 'ST.RS',
              accessor: item => (isNaN(item.ST.RS) ? item.ST.RS : Number(item.ST.RS).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - RN',
              id: 'MVA.RN',
              accessor: item => (isNaN(item.MVA.RN) ? item.MVA.RN : Number(item.MVA.RN).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - RN',
              id: 'ST.RN',
              accessor: item => (isNaN(item.ST.RN) ? item.ST.RN : Number(item.ST.RN).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - RJ',
              id: 'MVA.RJ',
              accessor: item => (isNaN(item.MVA.RJ) ? item.MVA.RJ : Number(item.MVA.RJ).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - RJ',
              id: 'ST.RJ',
              accessor: item => (isNaN(item.ST.RJ) ? item.ST.RJ : Number(item.ST.RJ).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - RO',
              id: 'MVA.RO',
              accessor: item => (isNaN(item.MVA.RO) ? item.MVA.RO : Number(item.MVA.RO).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - RO',
              id: 'ST.RO',
              accessor: item => (isNaN(item.ST.RO) ? item.ST.RO : Number(item.ST.RO).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - RR',
              id: 'MVA.RR',
              accessor: item => (isNaN(item.MVA.RR) ? item.MVA.RR : Number(item.MVA.RR).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - RR',
              id: 'ST.RR',
              accessor: item => (isNaN(item.ST.RR) ? item.ST.RR : Number(item.ST.RR).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - SC',
              id: 'MVA.SC',
              accessor: item => (isNaN(item.MVA.SC) ? item.MVA.SC : Number(item.MVA.SC).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - SC',
              id: 'ST.SC',
              accessor: item => (isNaN(item.ST.SC) ? item.ST.SC : Number(item.ST.SC).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - SP',
              id: 'MVA.SP',
              accessor: item => (isNaN(item.MVA.SP) ? item.MVA.SP : Number(item.MVA.SP).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - SP',
              id: 'ST.SP',
              accessor: item => (isNaN(item.ST.SP) ? item.ST.SP : Number(item.ST.SP).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - SE',
              id: 'MVA.SE',
              accessor: item => (isNaN(item.MVA.SE) ? item.MVA.SE : Number(item.MVA.SE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - SE',
              id: 'ST.SE',
              accessor: item => (isNaN(item.ST.SE) ? item.ST.SE : Number(item.ST.SE).toLocaleString()),
              width: 100,
            },
            {
              Header: '% MVA - TO',
              id: 'MVA.TO',
              accessor: item => (isNaN(item.MVA.TO) ? item.MVA.TO : Number(item.MVA.TO).toLocaleString()),
              width: 100,
            },
            {
              Header: '% ST - TO',
              id: 'ST.TO',
              accessor: item => (isNaN(item.ST.TO) ? item.ST.TO : Number(item.ST.TO).toLocaleString()),
              width: 100,
            },
            {
              Header: 'CEST',
              id: 'CEST',
              accessor: 'CEST',
              width: 100,
            },
            {
              Header: 'Comprimento',
              id: 'Comprimento',
              accessor: item => item.comprimento.toLocaleString(),
              width: 150,
            },
            {
              Header: 'Largura',
              id: 'Largura',
              accessor: item => item.largura.toLocaleString(),
              width: 100,
            },
            {
              Header: 'Altura',
              id: 'Altura',
              accessor: item => item.altura.toLocaleString(),
              width: 100,
            },
            {
              Header: 'Peso Bruto',
              id: 'PesoBruto',
              accessor: item => item.pesoBruto.toLocaleString(),
              width: 100,
            },
            {
              Header: 'Cor',
              id: 'Cor',
              accessor: 'cor',
              width: 100,
            },
            {
              Header: 'Material',
              id: 'Material',
              accessor: 'material',
              style: { whiteSpace: 'unset' },
              width: 150,
            },
            {
              Header: 'Outlet',
              id: 'Outlet',
              accessor: 'outlet',
              width: 150,
            },
            {
              Header: 'Promoção',
              id: 'Promocao',
              accessor: 'promocao',
              width: 150,
            },
            {
              Header: 'TIPO',
              accessor: 'tipo',
              width: 150,
              id: "TIPO"
            },
          ]}
        />
      </div>
    );
  }
}

ItensGeradora.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.object).isRequired,
  desconto: PropTypes.number.isRequired,
};
