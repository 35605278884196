/* eslint-disable */
import { utils, writeFile } from "sheetjs-style";
import FileSaver from "file-saver";

const styleCell = {
  font: {
    name: "Verdana",
    sz: 8,
    bold: false,
    color: { rgb: "000000" },
  },
  alignment: {
    vertical: "center",
    horizontal: "center",
    wrapText: true,
  },
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
};
const columnMoneys = ["M", "P", "R"];
const columnDesc = [
  "N",
  "O",
  "Q",
  "AH",
  "AL",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
  "BA",
  "BB",
  "BC",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BK",
  "BL",
  "BM",
  "BN",
  "BO",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BX",
  "BY",
  "BZ",
  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CJ",
];

const parsePorcentagem = (value) => {
  let parseValue = typeof value === "number" ? value : Number(value);
  if (isNaN(parseValue)) {
    return 0;
  }
  return Number(parseValue.toFixed(4)) / 100;
};

const parseDataXls = (data, desconto) => {
  const parseData = data.map((item) => {
    const desc =
      Number(desconto) > Number(item.promoSP)
        ? Number(desconto)
        : Number(item.promoSP);
    const descForaSP =
      Number(desconto) > Number(item.promoForaSP)
        ? Number(desconto)
        : Number(item.promoForaSP);
    return {
      FOTO: item.imageBase64 ? item.imageBase64 : item.urlFoto,
      REF: isNaN(item.itemCode) ? item.itemCode : Number(item.itemCode),
      DESCRIÇÃO: item.frgnName,
      "COD EAN": Number(item.codeBars),
      "COD DUN": Number(item.codDun),
      MARCA: item.marca,
      CATEGORIA: item.categoria,
      SUBCATEGORIA: item.subcategoria,
      LINHA: item.linha,
      FONTE: item.fonteProduto,
      ORIGEM: item.paisOrigem,
      ONHOLD: item.onHold === "N" ? "Não" : "Sim",
      "PREÇO SEM DESCONTO": Number(item.preco),
      "% DE DESCONTO": parsePorcentagem(desconto),
      "PROMOÇÃO DENTRO SP": Number((Number(item.promoSP) / 100).toFixed(4)),
      "PREÇO COM DESCONTO SP": Number(item.preco) * (1 - desc / 100),
      "PROMOÇÃO FORA SP": Number((Number(item.promoForaSP) / 100).toFixed(4)),
      "PREÇO COM DESCONTO FORA SP": Number(item.preco) * (1 - descForaSP / 100),
      "ESTOQUE FÍSICO": Number(item.estoqueCajamar),
      "PEDIDOS EM CARTEIRA": Number(item.reservadoCajamar),
      "RESERVAS PARA PEDIDO DE VENDA": Number(item.bloqueadoPedido),
      "PRÉ-FATURAS EM CARGA": item.picking,
      "EM TRÂNSITO": Number(item.transitoCajamar),
      "PREVISTO EMBARCADO": Number(item.embarcado1),
      "PREV CHEGADA":
        item.dataPrevisao && item.dataPrevisao.trim() === "01/01/1970"
          ? "-"
          : item.dataPrevisao,
      PRODUZINDO: Number(item.produzindo),
      "DATA PREV EMB":
        item.dataPrevEmb && item.dataPrevEmb.trim() === "01/01/1970"
          ? "-"
          : item.dataPrevEmb,

      "Disp. Fat. Imediato (Fisico-Pedidos em Carteira+Em Trânsito)": Number(
        item.disp1
      ),
      "ENTRADA DE CONTAINER": Number(item.entradaContainer),
      "QTD MASTER": Number(item.qtdCaixaMaster),
      "QTD INNER": Number(item.qtdCaixaInner),
      "TIPO EMBALAGEM": item.tipoEmbalagem,
      NCM: item.ncmCode,
      IPI: parsePorcentagem(item.IPI),
      "MVA.AC": parsePorcentagem(item.MVA.AC),
      "ST.AC": parsePorcentagem(item.ST.AC),
      "MVA.AL": parsePorcentagem(item.MVA.AL),
      "ST.AL": parsePorcentagem(item.ST.AL),
      "MVA.AM": parsePorcentagem(item.MVA.AM),
      "ST.AM": parsePorcentagem(item.ST.AM),
      "MVA.AP": parsePorcentagem(item.MVA.AP),
      "ST.AP": parsePorcentagem(item.ST.AP),
      "MVA.BA": parsePorcentagem(item.MVA.BA),
      "ST.BA": parsePorcentagem(item.ST.BA),
      "MVA.CE": parsePorcentagem(item.MVA.CE),
      "ST.CE": parsePorcentagem(item.ST.CE),
      "MVA.DF": parsePorcentagem(item.MVA.DF),
      "ST.DF": parsePorcentagem(item.ST.DF),
      "MVA.ES": parsePorcentagem(item.MVA.ES),
      "ST.ES": parsePorcentagem(item.ST.ES),
      "MVA.GO": parsePorcentagem(item.MVA.GO),
      "ST.GO": parsePorcentagem(item.ST.GO),
      "MVA.MA": parsePorcentagem(item.MVA.MA),
      "ST.MA": parsePorcentagem(item.ST.MA),
      "MVA.MT": parsePorcentagem(item.MVA.MT),
      "ST.MT": isNaN(item.ST.MT) ? item.ST.MT : parsePorcentagem(item.ST.MT),
      "MVA.MS": parsePorcentagem(item.MVA.MS),
      "ST.MS": parsePorcentagem(item.ST.MS),
      "MVA.MG": parsePorcentagem(item.MVA.MG),
      "ST.MG": parsePorcentagem(item.ST.MG),
      "MVA.PA": parsePorcentagem(item.MVA.PA),
      "ST.PA": parsePorcentagem(item.ST.PA),
      "MVA.PB": parsePorcentagem(item.MVA.PB),
      "ST.PB": parsePorcentagem(item.ST.PB),
      "MVA.PR": parsePorcentagem(item.MVA.PR),
      "ST.PR": parsePorcentagem(item.ST.PR),
      "MVA.PE": parsePorcentagem(item.MVA.PE),
      "ST.PE": parsePorcentagem(item.ST.PE),
      "MVA.PI": parsePorcentagem(item.MVA.PI),
      "ST.PI": parsePorcentagem(item.ST.PI),
      "MVA.RN": parsePorcentagem(item.MVA.RN),
      "ST.RN": parsePorcentagem(item.ST.RN),
      "MVA.RS": parsePorcentagem(item.MVA.RS),
      "ST.RS": parsePorcentagem(item.ST.RS),
      "MVA.RJ": parsePorcentagem(item.MVA.RJ),
      "ST.RJ": parsePorcentagem(item.ST.RJ),
      "MVA.RO": parsePorcentagem(item.MVA.RO),
      "ST.RO": parsePorcentagem(item.ST.RO),
      "MVA.RR": parsePorcentagem(item.MVA.RR),
      "ST.RR": parsePorcentagem(item.ST.RR),
      "MVA.SC": parsePorcentagem(item.MVA.SC),
      "ST.SC": parsePorcentagem(item.ST.SC),
      "MVA.SP": parsePorcentagem(item.MVA.SP),
      "ST.SP": parsePorcentagem(item.ST.SP),
      "MVA.SE": parsePorcentagem(item.MVA.SE),
      "ST.SE": parsePorcentagem(item.ST.SE),
      "MVA.TO": parsePorcentagem(item.MVA.TO),
      "ST.TO": parsePorcentagem(item.ST.TO),
      CEST:
        isNaN(item.CEST) && item.CEST.trim() === ""
          ? item.CEST
          : Number(item.CEST),
      COMPRIMENTO: item.comprimento,
      LARGURA: item.largura,
      ALTURA: item.altura,
      "PESO BRUTO": item.pesoBruto,
      COR: item.cor,
      MATERIAL: item.material,
      OUTLET: item.outlet,
      PROMOÇÃO: item.promocao
    };
  });
  return parseData;
};

const getSizeColumns = (sizeRows, sizeColumns) => {
  const defaultSize = 20;
  const wsRows = [];
  const wsCols = [];

  for (let i = 0; i <= sizeColumns; i++) {
    if (i === 0 || i === 1) {
      wsCols.push({ width: 10 });
    } else if (i === 2) {
      wsCols.push({ width: 45 });
    } else if (i >= 33) {
      wsCols.push({ width: 10 });
    } else {
      wsCols.push({ width: defaultSize });
    }
  }

  for (let i = 0; i <= sizeRows; i++) {
    wsRows.push({
      hpx: i === 0 ? 35 : 50,
    });
  }

  return {
    wsRows: wsRows,
    wsCols: wsCols,
  };
};

const gerarExcel = (data, desconto) => {
  let sizeColumn = 0;
  const parseData = parseDataXls(data, desconto);
  const ws = utils.json_to_sheet(parseData);
  const wb = utils.book_new();

  for (const [index, linha] of parseData.entries()) {
    if (linha.FOTO.slice(0, 5) == 'https') {
      utils.sheet_set_array_formula(
        ws,
        `A${index + 2}`,
        `_xlfn.IMAGE("${linha.FOTO}",${linha.REF}, 1)`,
        1
      );
    } else {
      linha.FOTO = new Image()
      utils.sheet_set_array_formula(
        ws,
        `A${index + 2}`,
        `${linha.FOTO}`,
        1
      );
      console.log(ws, 'ws')
    }
  }

  for (const i in ws) {
    if (typeof ws[i] != "object") continue;
    const column = i.replace(/[0-9]/g, "");
    let cell = utils.decode_cell(i);
    if (cell.r === 0) {
      let colorColumn = "";
      let colorFont = column === "O" || column === "Q" ? "FF0000" : "000000";

      if (column === "M") {
        colorColumn = "FCD5B4";
      } else if (column === "P" || column === "R") {
        colorColumn = "D8E4BC";
      } else {
        colorColumn = "DCE6F1";
      }

      sizeColumn++;
      ws[i].s = {
        ...styleCell,
        font: {
          ...styleCell.font,
          bold: true,
          color: { rgb: colorFont },
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: colorColumn },
        },
      };
    } else {
      let colorColumn = "";
      if (column === "M") {
        colorColumn = "FDE9D9";
      } else if (column === "P" || column === "R") {
        colorColumn = "EBF1DE";
      } else {
        colorColumn = "";
      }

      if (columnMoneys.indexOf(column) !== -1) {
        ws[i].s = { ...styleCell, numFmt: "R$ 0.00" };
      } else if (column === "D" || column === "E") {
        ws[i].t = "s";
        ws[i].s = { ...styleCell };
      } else if (columnDesc.indexOf(column) !== -1) {
        ws[i].s = { ...styleCell, numFmt: "0.00%" };
      } else {
        ws[i].s = { ...styleCell };
      }

      if (colorColumn !== "") {
        ws[i].s = {
          ...ws[i].s,
          fill: {
            patternType: "solid",
            fgColor: { rgb: colorColumn },
          },
        };
      }
    }
  }

  const { wsCols, wsRows } = getSizeColumns(parseData.length, sizeColumn);
  ws["!cols"] = wsCols;
  ws["!rows"] = wsRows;

  utils.book_append_sheet(wb, ws, "Data");
  return wb;
};

export const excelItensGeradora = (data, desconto = 0) => {
  const workbook = gerarExcel(data, desconto);
  return writeFile(workbook, "Planilha Geradora.xlsx");
};
