/* eslint-disable */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Geradora from '.';
import Login from './Login';

export default class AppGeradora extends React.PureComponent {
    state = {
      token: '',
      empresa: 0,
    }

    componentDidMount() {
      const { token: localToken } = localStorage;
      const { token: stateToken } = this.state;
      if (localToken) {
        // Teste condicional para previnir re-renders desnecessários
        if (stateToken !== localToken) { this.setState({ localToken }); }
      }
    }

    clearSession = () => {
      localStorage.clear();
      this.setState({ token: '', empresa: 0 });
    }

    setUser = (token, empresa) => {
      localStorage.token = token;
      localStorage.empresa = empresa;
      this.setState({ token, empresa });
    }

    render() {
      const { token: stateToken } = this.state;
      const token = localStorage.token || stateToken;
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={props => (<Login setUser={this.setUser} {...props} />)} />
            <Route path="*" render={props => (<Geradora token={token} clearSession={this.clearSession} {...props} />)} />
          </Switch>
        </BrowserRouter>
      );
    }
}
